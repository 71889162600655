@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: sans-serif;
  margin: 0;
}

/* scrollbar styling */
*::-webkit-scrollbar {
  width: 0.5rem;
  background: #fff !important;
  height: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 20px;
}

/* colors */
:root {
  --bg-color-primary: #08082f;
  --bg-color-secondary: #232356;
  --bg-color-tertiary: #0B0B43;
  --bg-color-dark: #222;
  --bg-color-light: #f5f5f5;
  --font-color-primary: #08082f;
  --font-color-secondary: #232356;
  --font-color-tertiary: #0B0B43;
  --color-dark: #000;
  --color-light: #f5f5f5;
  --font-family: 'Open Sans', sans-serif;
  --font-size-xsmall: 10px;
  --font-size-small: 12px;
  --font-size-normal: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;
}

.app {
  display: flex;
  position: relative;
}

.content {
  background-color: #EDEDF4;
  overflow-y: auto;
}

/* inputButton */
/* .inputWithButton {
  position: relative;
  padding: 0;
}

.inputWithButton button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.inputWithButton button span {
  margin: 0;
  padding: 0;
} */

/* modal */
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 450px;
  max-height: 90vh;
  background: var(--bg-color-light);
  border-radius: 10px;
}

.modal-container .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.modal-container .modal-title {
  color: 'black';
  font-size: var(--font-size-normal);
  text-transform: uppercase;
  font-weight: 600;
}

.modal-container .delete-title {
  padding: 10px 20px !important;
  font-size: 16px;
}

.modal-container .modal-body {
  padding: 24px 20px;
  max-height: 70vh;
  overflow-y: auto;
}

/* 
.modal-container .modal-body .modal-input {
  margin-bottom: 1rem;
}

.modal-container .modal-body section .section-title {
  margin-bottom: 10px;
} */

.modal-container .modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 20px 20px 20px;
  /* margin-top: 10px; */
}

.modal-container .modal-footer.delete-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 20px;
  padding-bottom: 10px;
  position: relative;
  margin-top: 10px;
}

.modal-container .modal-footer.delete-footer::after {
  content: '';
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -10px;
  left: 20px;
  right: 20px;
}

.modal-footer .modal-button-primary {
  background: #2266EE;
  color: white;
  padding: 5px 30px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: capitalize;
  font-weight: normal;
}

.modal-footer .modal-button-primary:hover {
  background: #1a4fcb;
}

.modal-footer .modal-button-secondary {
  background: #f5f5f5;
  color: #373838;
  padding: 5px 30px;
  border-radius: 25px;
  border: 1px solid #8A8B8C;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: capitalize;
  font-weight: normal;
}

.modal-footer .modal-button-secondary:hover {
  background: #e5e5e5;
}

/* card */
.card {
  border-radius: 15px;
  padding: 5px 20px;
  position: relative;
  width: 200px;
  /* box-shadow: 3px 3px 1px */
  flex: 1;
}

.card__container {
  display: flex;
  justify-content: space-between;
}

.card .card__header {
  background: #4CA750;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border-radius: 15px;
  color: white;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.card .card__body {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
}

.card .card__body .card__body__title {
  font-size: 1rem;
}

.card .card__body .card__body__content {
  font-size: 1.2rem;
  font-weight: bolder;
}

.card .card__divider {
  margin: 5px 0;
}

.simple-card {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr auto;
}

.simple-card__header {
  font-weight: 700;
  font-size: 1.5rem;
}

.simple-card__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}

.simple-card__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-table {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-auto-flow: row;
}

.simple-table input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #14877c;
  padding: 0 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.simple-table button {
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  user-select: none;
  font-weight: 600;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #14877c;
  background-color: #14877c;
  cursor: pointer;
  margin: 0px 15px;
  min-width: 100px;
}

.simple-table button:hover {
  background: #14877c;
  color: #ffffff;
}

.simple-table table {
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 1em;
  margin: 10px 0;
  margin-top: 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

.simple-table thead {
  background-color: black;
  font-size: 16px;
  color: white;
}

.simple-table th {
  text-align: left;
  padding: 8px;
  color: white;
}

.simple-table td {
  text-align: left;
  padding: 8px;
}

.simple-table tr:nth-child(even) {
  background-color: #e0e0e0;
}

.simple-table .search {
  display: flex;
  justify-content: flex-end;
}

.simple-table table thead tr :not(:first-child) {
  text-align: center;
}

.simple-table table tbody tr :not(:first-child) {
  text-align: center;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .css-r6ewbb-MuiToolbar-root {
    min-height: 50px !important;
  }
}

@media (max-width: 600px) {
  .modal-container {
    min-width: 95% !important;
    max-width: 95% !important;
  }
}

/* Sidebar */
.pro-sidebar {
  z-index: 99;
  user-select: none;
}

.pro-sidebar-inner {
  color: #f5f5f5 !important;
  background: black !important;
}

.pro-inner-list-item {
  background: black !important;
}

.pro-inner-list-item .popper-inner {
  padding-left: 0px !important;
  background: black !important;
}

.pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-inner-item {
  padding: 5px 20px 3px 25px !important;
  font-size: 14px;
}

.pro-inner-item:hover {
  color: #868dfb !important;
}

.pro-menu-item.active {
  color: #6870fa !important;
}

.react-slidedown ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.react-slidedown ul li {
  padding: 5px 35px 5px 0px !important;
}

/* backdrop filter
.MuiBackdrop-root {
  backdrop-filter: blur(5px);
} */

/* input field */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

/* table */